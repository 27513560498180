"use strict";

var permissionConfig = {};




function loadPermissionMasks(config) {
  config.permissionMasks = {
    inviteToken: {
        create : 1n << 0n,
        list: 1n << 1n,
    },
    metricKey: {
        get: 1n << 2n,
    },
    organisation: {
        get: 1n << 3n,
        setVod: 1n << 4n,
        setWebhook: 1n << 5n
    },
    stream: {
        getStreamListCount: 1n << 6n,
        getAllStreamStatesCount: 1n << 7n,
        validateStream: 1n << 8n,
        getStreams: 1n <<  9n,
        createStream: 1n << 10n,
        getStreamgroups: 1n << 11n,
        getStreamgroupsCount: 1n << 12n,
        deleteStreams: 1n << 13n,
    },
    streamWithId: {
        getStream: 1n << 14n,
        getStreamGroup: 1n << 15n,
        setTag: 1n << 16n,
        setPushUrl: 1n << 17n,
        setTranscodingProfile:1n << 18n,
        setOpcode: 1n << 19n,
        setTimecode: 1n << 20n,
        addTranscode: 1n << 21n,
        deleteStream: 1n << 22n,
        removeTranscode: 1n << 23n,
        stopIngest: 1n << 24n,
        unlockStream: 1n << 25n
    },
    tag: {
        getTags: 1n << 26n,
        getRecentlyUsedTags: 1n << 27n,
        createTag: 1n << 28n,
        editTag: 1n << 29n,
        deleteTag: 1n << 30n,
    },
    transcoding_profiles: {
        getTranscodingProfiles: 1n << 31n
    },
    user: {
        getUser: 1n << 32n,
        deleteUser: 1n << 33n,
        renewUserTokenKey: 1n << 34n,
        changeUserPermissions: 1n << 35n,
        getPermissions: 1n << 36n
    }
  };
}

function loadPermissionUserType(config) {
    config.permissionProfile = {
        default: "nanoUser",
        admin : "nanoAdmin",
        user : "nanoUser",
        readonly: "nanoReadOnly"
    }
}


function initConfig() {
  loadPermissionMasks(permissionConfig);
  loadPermissionUserType(permissionConfig)
}

initConfig();

module.exports = permissionConfig;